<template>
  <template v-if="heading.attrs.level == 1">
    <h1 class="mt-5 text-primary">
      <template v-for="(content,index2) in heading.content" v-bind:key="index2">
        <template v-if="content.type=='text'">
          <span class="font-family-krona">{{content.text}}</span>
        </template>
      </template>
    </h1>
  </template>
  <template v-if="heading.attrs.level == 2">
    <h2 class="mt-5 text-primary">
      <template v-for="(content,index2) in heading.content" v-bind:key="index2">
        <template v-if="content.type=='text'">
          <span class="font-family-krona font-size-150">{{content.text}}</span>
        </template>
      </template>
    </h2>
  </template>
  <template v-if="heading.attrs.level == 3">
    <h3 class="mt-5 text-primary">
      <template v-for="(content,index2) in heading.content" v-bind:key="index2">
        <template v-if="content.type=='text'">
          <span class="font-family-krona">{{content.text}}</span>
        </template>
      </template>
    </h3>
  </template>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Heading',
  props: [
    'section',
  ],
  data() {
    return {
      heading: this.section
    };
  },
  created() {
    
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
